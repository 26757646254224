.partners {
    width: 100%;
    height: auto;
    padding: 80px 0;
}

.partners h3 {
    font-size: 48px;
}
.partners p {
    max-width: 1024px;
    width: 100%;
    padding: 24px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.6;
}

.imgs {
    max-width: 1024px;
    width: 100%;
    margin: 20px auto;
    display: grid;
    place-items: center center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
}

.partner-wrap {
    display: flex;
    place-items: center center;
    width: 220px;
    height: 150px;
}
.partner-wrap img{
    width: 100%;
}

@media(max-width: 769px) {
    .partners {
        height: auto;
        padding: 20px;
    }
    .partners p {
        padding: 20px;
        margin: 0 auto;
    }
    .imgs {
        max-width: 760px;
        width: 100%;
        margin: 20px auto;
        grid-template-columns: 1fr;
        overflow: hidden;
    }
    .partner-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
    }
    .partner-wrap img{
        width: 100%;
    }
}