.footer {
    width: 100%;
    height: 300px;
    background: #000;
    padding: 80px;
}

.footer p {
    color: #9f9f9f;
}

@media(max-width: 769px) {
    .footer {
        height: auto;
        padding: 20px;
    }

}