.contact {
    width: 100%;
    height: 400px;
    padding: 80px 0;
    background-color: #EFEFEF;
}

.contact h3 {
    font-size: 48px;
}
.contact p {
    font-size: 18px;
    line-height: 1.6;
}

@media(max-width: 769px) {
    .contact {
        height: auto;
        padding: 20px 20px 56px 20px;
    }
    .contact h3 {
        font-size: 36px;
    }
    .contact p {
        width: 100%;
        padding: 0 20px;
    }
}