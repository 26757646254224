.hero {
    position: relative;
    width: 100%;
    height: 900px;
    overflow: hidden;
}
.hero img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

}
.info {
    position: absolute;
    z-index: 2;
    bottom: 10%;
    width: 40%;
    height: 200px;
    background-color: #61636F;
    opacity: 0.9;
    padding: 2% 0;
    box-sizing: border-box;
}
.info h1 {
    font-size: 72px;
    color: white;
    margin: 5px 0;
}
.info h2 {
    font-size: 24px;
    color: #e5eafc;
    margin: 0;
}

@media(max-width: 769px) {
    .hero {
        height: 300px;
    }
    .hero img {
        width: unset;
        height: 100%;
    }
    .info {
        bottom: 10%;
        width: 80%;
        height: 100px;
        background-color: #61636F;
        opacity: 0.9;
        padding: 2% 0;
        box-sizing: border-box;
    }
    .info h1 {
        font-size: 38px;
        color: white;
        margin: 5px 0;
    }
    .info h2 {
        font-size: 18px;
        color: #e5eafc;
        margin: 0;
    }
}