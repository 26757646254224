.introduce {
    height: auto;
    padding: 40px 0 80px;
}

.introduce h3 {
    font-size: 48px;
}

.section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.img-box {
    position: relative;
    z-index: 3;
    width: 60%;
}

.img-wrap {
    height: 240px;
    overflow: hidden;
}

.square {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 10px solid #efefef;
    bottom: -40px;
    z-index: 1;
}

.left {
    right: -40px;
}

.right {
    left: -40px;
}

.img-box img {
    position: relative;
    z-index: 2;
    width: 100%;
}

.contents {
    padding: 20px 80px;
    font-size: 18px;
    line-height: 1.6;
    text-align: left;
}

@media (max-width: 769px) {
    .introduce {
        height: auto;
        padding: 20px;
    }

    .introduce h3 {
        font-size: 36px;
    }

    .section {
        flex-direction: column;
        width: 100%;
        height: auto;
    }
    .img-box {
        width: 100%;
        overflow: hidden;
    }
    .img-wrap {
        width: 100%;
    }
    .contents {
        padding: 0;
    }
    .square {
        display: none;
    }
}