.about {
    width: 100%;
    height: auto;
    background-color: #EFEFEF;
    padding: 80px 0;
}
.about h3 {
    font-size: 48px;
}
.about p {
    max-width: 1024px;
    width: 100%;
    padding: 24px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.6;
}

@media(max-width: 769px) {
    .about {
        height: auto;
        padding: 20px 20px 56px 20px;
    }
    .about h3 {
        font-size: 36px;
    }
    .about p {
        width: 100%;
        padding: 0 20px;
    }
}